.r-logo {
    width: 35%;
}

.bimg {
    width: 80%;
}

.rdbimg {
    height: 50%;
}


body {
    padding-top: 1px;
    overflow-x: hidden;
    /* Adjust this value based on the combined height of your Navbar and Navheader */
}


/* .fixed-top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1030;
} */





/* General Styles */
body {
    font-family: 'Arial', sans-serif;
}

.cd-hero {
    background-color: #f9f9f9;
    padding: 20px 0;
}

.cd-hero-slider {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.cd-hero-slider li {
    display: none;
}

.cd-hero-slider .selected {
    display: block;
}

/* Heading Styles */
.heading {
    text-align: center;
    /* margin-bottom: 20px; */
}

.heading h1 {
    font-size: 36px;
    color: #333;
}

.heading span {
    font-size: 18px;
    color: #777;
}

.heading p {
    margin: 0;
}

/* Form Container Styles */
.cd-full-width {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.first-slide {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.container {
    max-width: 800px;
    margin: 0 auto;
}

.row-custom {
    margin-bottom: 20px;
}



/* navheader page  styling start */
.navbar-custom {
    top: 40px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed !important;
    width: 100%;
    z-index: 1050;
    display: flex;
    align-items: center;
}

/* Ensure navbar items stay aligned */
.navbar-nav {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    /* Adjust spacing */
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
}

/* Ensure text and other nav items are aligned */
.navbar-text {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-left: 10px;
}

/* Keep the cart and user icon aligned */
.navbar-user-info {
    display: flex;
    align-items: center;
    gap: 15px;
    /* Adjust spacing */
}

/* Fix dropdown menu positioning */
.dropdown-menu {
    display: block;
    position: absolute;
    left: auto;
    right: 0;
}

/* Dropdown menu positioning */
.nav-icon .dropdown-menu.show {
    position: absolute;
    top: 25px;
    transform: translateX(-50%);
    background-color: white;
    text-align: center;
    z-index: 1000;
}

/* Navbar text style */
.navbar-text {
    font-weight: bold;
    margin-right: 15px;
}

/* Navbar dropdown items */
.dropdown-item {
    color: green;
    background-color: white;
}

.dropdown-item:hover {
    color: purple;
    background-color: white;
}

/* navheader page  styling end */


.carousel-item {
    margin-top: 10%;
}


/* Form Styles */
.login-content {
    padding: 20px;
    align-content: center;
}

.custom-label-login {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.form-custom {
    width: 100%;

}

.size {
    margin-bottom: 15px;
}

.custom-label {
    text-align: right;
    font-size: 16px;
    color: #333;
    padding-right: 10px;
}

.primary-button {
    text-align: center;
    margin-top: 20px;
}

.primary-button .btn {
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.primary-button .btn:hover {
    background-color: #218838;
}

.primary-button .btn-style {
    background-color: #f5f5f5;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
}

.primary-button .btn-style:hover {
    background-color: #fcfcfc;
}

.form-field {
    margin-bottom: 15px !important;

}

.nav-link.active {
    color: #007bff;
    /* Change this to your desired color */
    font-weight: bold;
}

.upiimg {

    max-width: 70%;
    height: auto
}

.category-container {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 10px 0;
    white-space: nowrap;
}

.category-item {
    flex: 0 0 auto;
    width: 250px;
    /* Adjust width as needed */
    text-align: center;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
}

.product-overlay {
    opacity: 0;
    /* Hide initially */
    transition: opacity 0.3s ease-in-out;
}

.product-card:hover .product-overlay {
    opacity: 1;
    /* Show only on hover */
}

.hover-card {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 10px;
    overflow: hidden;
}

.hover-card:hover {
    transform: scale(1.05);
    /* Slight zoom effect */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    /* Add shadow */
}

/* LOGIN page styling start */

/* Container styles */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
    margin-top: 30px;
}

/* Header styles */
.login-header {
    text-align: center;
    margin-bottom: 20px;
}

.login-header h1 {
    font-size: 2rem;
    margin-bottom: 5px;
}

.login-header span {
    font-size: 1rem;
    color: #555;
}

/* Form container */
.login-form-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

/* Form title */
.login-title {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: bold;
}

/* Form group */
.form-group {
    margin-bottom: 15px;
}

/* Input fields */
.input-field {
    width: 100%;
}

/* Error messages */
.alert-field {
    margin-top: 5px;
}

/* Button styles */
.login-btn {
    width: 100%;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    color: white;
}

.login-btn:hover {
    background: linear-gradient(90deg, #0072ff, #00c6ff);
}

/* Registration link */
.register-link {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #0072ff;
    font-weight: bold;
    margin-top: 10px;
}

.register-link:hover {
    text-decoration: underline;
}

/* LOGIN page styling end */


/* Home page Categoery styling start */
/* Carousel Styling */
#template-mo-zay-hero-carousel {
    width: 100%;
}

.carousel-inner {
    position: relative;
}

.carousel-item {
    text-align: center;
}

.bimg {
    max-width: 100%;
    height: auto;
}

.carousel-control-prev,
.carousel-control-next {
    width: auto;
    padding: 10px;
}

/* Category Slider Styling */
.category-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
}

.category-scroll-container {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 10px 0;
    width: 100%;
}

.category-item {
    text-align: center;
    min-width: 180px;
    max-width: 200px;
    padding: 10px;
    border-radius: 10px;
    background: #f8f9fa;
    transition: transform 0.3s ease-in-out;
}

.category-item:hover {
    transform: scale(1.05);
}

.arrow-btn {
    cursor: pointer;
    border: none;
    background: none;
    font-size: 3rem;
    color: #0072ff;
}

.arrow-btn:hover {
    color: #0056b3;
}

/* Button Styling */
.shop-button {
    margin-top: 5px;
    padding: 5px 10px;
    min-width: 30px;
    font-size: 0.8rem;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    color: #fff !important;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.shop-button:hover {
    background: linear-gradient(90deg, #0072ff, #00c6ff);
    color: #fff;
}

/* Home page Categoery styling end */


/* SHop page styling start */

.container-box {
    background-color: #d1984e94;
    margin-top: 130px;
    padding: 60px;
}

.filter-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.filter-control {
    width: 200px;
}

.product-card {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.product-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.product-media {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
}

.product-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-overlay:hover {
    opacity: 1;
}

.overlay-link {
    text-decoration: none;
}

.overlay-icon {
    color: white;
    background-color: rgba(255, 255, 255, 0.2);
}

.card-content {
    text-align: center;
}

.quantity-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid yellow;
    border-radius: 5px;
    width: fit-content;
    margin: 10px auto;
    /* Centers the box horizontally */
    padding: 5px;
}

.quantity-text {
    margin: 0 10px;
}

.add-to-cart {
    max-width: 80%;
    margin-top: 10px;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    color: white;
}

.add-to-cart:hover {
    background: linear-gradient(90deg, #0072ff, #00c6ff);
}

.no-products {
    text-align: center;
    width: 100%;
}

/* SHop page styling END */

/* PRODDETAILS page styling START */

.product-container {
    background-color: #d1984e94;
    min-height: 100vh;
    padding: 40px 0;
    margin-top: 9%;
}

.product-image-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.product-details-card {
    padding: 24px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.product-title {
    font-weight: bold;
}

.product-price {
    color: green;
    margin-top: 8px;
}

.product-subtitle {
    color: #757575;
    margin-top: 16px;
}

.quantity-container {
    margin-top: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.quantity-boxproddetails {
    display: flex;
    align-items: center;
    margin-left: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 8%;
}

.quantity-text {
    margin: 0 16px;
}

.add-to-cart-btn {
    margin-top: 24px;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    color: white;
}

.add-to-cart-btn:hover {
    background: linear-gradient(90deg, #0072ff, #00c6ff);
}

.customer-reviews-card {
    padding: 16px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.review-box {
    padding: 16px;
    border-bottom: 1px solid #ddd;
}

.review-author {
    font-weight: bold;
}

.review-rating {
    display: flex;
    align-items: center;
}

.rating-text {
    margin-left: 8px;
}

.review-text {
    margin-top: 8px;
}

.no-reviews {
    color: #757575;
}

.review-heading {
    margin-top: 16px;
}

.review-subtext {
    color: #757575;
    margin-bottom: 24px;
}

.write-review-btn {
    width: 19.1%;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    color: white;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.write-review-btn:hover {
    background: linear-gradient(90deg, #0072ff, #00c6ff);
}


/* PRODDETAILS page styling END */

/* ADDTOCART STYLING START */

.cart-container {
    background-color: #d1984e94;
    padding: 30px;
    text-align: center;
    margin-top: 130px;
}

.cart-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

.cart-empty {
    text-align: center;
    margin-top: 20px;
}

.cart-empty h4,
.cart-empty h5 {
    margin-bottom: 10px;
}

.cart-empty .btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.cart-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 15px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.cart-item:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.cart-header {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.cart-checkbox {
    transform: scale(1.2);
}

.cart-product-image {
    max-width: 100%;
    height: auto;
}

.cart-item-details {
    width: 100%;
    padding: 10px;
}

.cart-product-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.cart-product-price,
.cart-product-weight,
.cart-product-total {
    font-size: 16px;
    margin: 5px 0;
}

.quantity-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.quantity-btn {
    background-color: #ddd;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
}

.quantity {
    margin: 0 10px;
    font-size: 16px;
    font-weight: bold;
}

.remove-btn {
    margin-top: 10px;
    background: linear-gradient(90deg, #ff4e50, #ff6a00);
    color: white;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 45%;
}

.remove-btn:hover {
    background: linear-gradient(90deg, #ff6a00, #ff4e50);
}

.proceed-btn {
    margin-top: 10px;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.proceed-btn:hover {
    background: linear-gradient(90deg, #0072ff, #00c6ff);
}

/* ADDTOCART STYLING END */

/* Productpurchase STYLING START */

/* Purchase Summary STYLING START */

.purchase-container {
    padding: 24px 0;
}

.summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.purchase-empty {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #888;
    margin-top: 20px;
}

.product-card {
    max-width: 345px;
    border-radius: 8px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}

.product-card:hover {
    transform: scale(1.05);
    box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.3);
}

.total-amount {
    font-weight: bold;
}
/* Purchase Summary STYLING END */

/* EDIT ADDRESS STYLING START */

.address-container {
    margin-top: 1rem;
}

.address-box {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

/* EDIT ADDRESS STYLING END */

/* Review Your Order STYLING START */

.review-container {
    margin-top: 1rem;
}

.grid-container {
    margin-top: 1rem;
}

.upi-img {
    max-width: 200px;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.order-details-box {
    margin-top: 1.5rem;
}

.error-message {
    color: red;
    margin-top: 0.5rem;
}

/* Review Your Order STYLING END */

/* Order Details STYLING START */

.order-details-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
}

.order-grid {
    margin-top: 20px;
}


/* Order Details STYLING END */

/* Productpurchase checkout-container START */

.checkout-container {
    margin-top: 12rem;
    margin-bottom: 10rem;
}

.checkout-paper {
    padding: 2rem;
    background-color: #eee8b2 !important;
}

.payment-option {
    margin-top: 1rem;
}

.back-button,
.next-button,
.download-button,
.shop-button {
    margin-top: 1rem;
    color: #fff;
}

.back-button {
    background: linear-gradient(90deg, #ff4e50, #ff6a00);
    margin-right: 1rem;
}

.back-button:hover {
    background: linear-gradient(90deg, #ff6a00, #ff4e50);
}

.next-button {
    background: linear-gradient(90deg, #00c6ff, #0072ff);
}

.next-button:hover {
    background: linear-gradient(90deg, #0072ff, #00c6ff);
}

.download-button {
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    margin-right: 1rem;
}

.download-button:hover {
    background: linear-gradient(90deg, #0072ff, #00c6ff);
}

.shop-button {
    background: linear-gradient(90deg, #4caf50, #2e7d32);
}

.shop-button:hover {
    background: linear-gradient(90deg, #2e7d32, #4caf50);
}

.total-amount-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.button-container {
    display: flex;
    gap: 1rem; /* Adds space between buttons */
    margin-top: 1rem;
}

.button-container .back-button {
    margin-right: auto; /* Pushes the 'Back' button to the left */
}

.button-container .next-button {
    margin-left: auto; /* Pushes the 'Next' button to the right */
}

.button-container .download-button {
    margin-right: auto; /* Pushes 'Download Invoice' to the left */
}

.button-container .shop-button {
    margin-left: auto; /* Pushes 'Go to Shop' to the right */
}

/* Productpurchase checkout-container END */

/* Productpurchase STYLING END */

/* CONTACT PAGE STYLING START */

/* Background and spacing for main container */
.contact-container {
    padding: 5rem 0;
    margin-top: 150px;
  }
  
  /* Spacing for form container */
  .contact-form-container {
    padding: 5rem 0;
  }
  
  /* Styles for the form paper (shadow and padding) */
  .contact-form-paper {
    padding: 1.5rem;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    /* background-color: #ebf1b5 !important; */

  }
  
  /* Button container alignment */
  .contact-button-container {
    display: flex;
    justify-content: flex-end;
  }
  
  /* Contact form button */
  .contact-button {
    margin-top: 1rem;
    text-transform: none;
    padding: 0.75rem 2rem;
    border-radius: 1rem;
  }
  

/* CONTACT PAGE STYLING END */


/* ABOUT PAGE STYLING START  */

.about{
    margin-top: 130px;
}

/* ABOUT PAGE STYLING END */


/* USERPURCHASE HISTORY STYLING START */

.purchasehistory {
    margin-top: 10rem;
}

.order-message {
    margin-top: 3rem;
}

.order-details {
    margin-top: 2rem;
    border-top: 1px solid #ccc;
    padding-top: 2rem;
}

.order-info {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.card-style {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-content {
    flex: 1;
}

.review-button,
.submit-review-button {
    width: 50%;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    color: #fff;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.review-button:hover,
.submit-review-button:hover {
    background: linear-gradient(90deg, #0072ff, #00c6ff);
}

.submit-review-button {
    width: 67%;
}

/* USERPURCHASE HISTORY STYLING END */

/* NAVBAR MEDIA PAGE START 320 */




/* NAVBAR MEDIA PAGE END 320 */

/* NAVHEADER MEDIA PAGE START 320 */

@media only screen and (max-width: 320px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
    }

    .navbar-nav-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
        max-height: 200px; /* Set max height for scrolling */
        overflow-y: auto; /* Enables vertical scrolling */
        overflow-x: hidden;
        background: white;
        text-align: center;
        z-index: 1000;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 320 */

/* NAVHEADER MEDIA PAGE START 480 */

@media only screen and (max-width: 480px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
    }

    .navbar-nav-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 480 */

/* NAVHEADER MEDIA PAGE START 576 */

@media only screen and (max-width: 576px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
    }

    .navbar-nav-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 576 */


/* NAVHEADER MEDIA PAGE START 768 */
@media (max-width: 768px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
    }

    .navbar-nav-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: center !important;
    }

    .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 768 */

/* NAVHEADER MEDIA PAGE START 920 */

@media only screen and (max-width: 920px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
    }

    .navbar-nav-container {
        display: flex;
        align-items: right;
        flex-grow: 1;
        justify-content: center !important;
    }

    .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 920 */


/* NAVHEADER MEDIA PAGE START 1024 */

@media only screen and (max-width: 1024px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
    }

    .navbar-nav-container {
        display: flex;
        align-items: right;
        flex-grow: 1;
        justify-content: center;
        justify-content: right;
    }

    .navbar-nav {
        display: flex-end;
        flex-wrap: wrap;
        align-items: center;
        justify-content: right;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 1024 */


/* HOME PAGE START 320 */
@media only screen and (max-width: 320px) {
    #template-mo-zay-hero-carousel {
        margin-top: 100px;
        /* Adjust the value as needed */
    }

    .carousel .row {
        flex-direction: column;
        text-align: center;
    }

    .carousel .col-lg-8 {
        order: 2;
        padding-top: 15px;
    }

    .carousel .col-lg-4 {
        order: 1;
    }

    .carousel img {
        max-width: 100%;
        height: auto;
    }

    .carousel h1,
    .carousel h3,
    .carousel p {
        font-size: 14px;
        /* Adjust for better readability */
    }
}

/* HOME PAGE END 320 */

/* HOME PAGE START 480 */
@media only screen and (max-width: 480px) {
    #template-mo-zay-hero-carousel {
        margin-top: 100px;
        /* Adjust the value as needed */
    }

    .carousel .row {
        flex-direction: column;
        text-align: center;
    }

    .carousel .col-lg-8 {
        order: 2;
        padding-top: 15px;
    }

    .carousel .col-lg-4 {
        order: 1;
    }

    .carousel img {
        max-width: 100%;
        height: auto;
    }

    .carousel h1,
    .carousel h3,
    .carousel p {
        font-size: 14px;
        /* Adjust for better readability */
    }
}

/* HOME PAGE END 480 */


/* HOME PAGE START 576 */
@media only screen and (max-width: 576px) {
    #template-mo-zay-hero-carousel {
        margin-top: 100px;
        /* Adjust the value as needed */
    }

    .carousel .row {
        flex-direction: column;
        text-align: center;
    }

    .carousel .col-lg-8 {
        order: 2;
        padding-top: 15px;
    }

    .carousel .col-lg-4 {
        order: 1;
    }

    .carousel img {
        max-width: 100%;
        height: auto;
    }

    .carousel h1,
    .carousel h3,
    .carousel p {
        font-size: 14px;
        /* Adjust for better readability */
    }
}

/* HOME PAGE END 576 */


/* HOME PAGE START 768 */
@media only screen and (max-width: 768px) {
    #template-mo-zay-hero-carousel {
        margin-top: 100px;
        /* Adjust the value as needed */
    }

    .carousel .row {
        flex-direction: column;
        text-align: center;
    }

    .carousel .col-lg-8 {
        order: 2;
        padding-top: 15px;
    }

    .carousel .col-lg-4 {
        order: 1;
    }

    .carousel img {
        max-width: 100%;
        height: auto;
    }

    .carousel h1,
    .carousel h3,
    .carousel p {
        font-size: 14px;
        /* Adjust for better readability */
    }
}

/* HOME PAGE END 768 */

/* HOME PAGE START 920 */
@media only screen and (max-width: 920px) {
    #template-mo-zay-hero-carousel {
        margin-top: 100px;
        /* Adjust the value as needed */
    }

    .carousel .row {
        flex-direction: column;
        text-align: center;
    }

    .carousel .col-lg-8 {
        order: 2;
        padding-top: 15px;
    }

    .carousel .col-lg-4 {
        order: 1;
    }

    .carousel img {
        max-width: 100%;
        height: auto;
    }

    .carousel h1,
    .carousel h3,
    .carousel p {
        font-size: 14px;
        /* Adjust for better readability */
    }
}

/* HOME PAGE END 920 */


/* HOME PAGE START 1024 */
@media only screen and (max-width: 1024px) {
    #template-mo-zay-hero-carousel {
        margin-top: 100px;
        /* Adjust the value as needed */
    }

    .carousel .row {
        flex-direction: row;
        text-align: center;
    }

    .carousel .col-lg-8 {
        order: 2;
        padding-top: 15px;
    }

    .carousel .col-lg-4 {
        order: 1;
    }

    .carousel img {
        max-width: 100%;
        height: auto;
    }

    .carousel h1,
    .carousel h3,
    .carousel p {
        font-size: 14px;
        /* Adjust for better readability */
    }
}

/* HOME PAGE END 1024 */

/* SHOP PAGE START 320 */
@media only screen and (max-width: 320px) {
    .container-box {
        padding: 15px;
        min-height: 100%;
        /* Adjust this value as needed */
    }

    .filter-control {
        width: 48%;
    }

    .product-media {
        height: 150px;
    }

    .card-description {
        max-height: 200px;
        font-size: 12px;
    }

    .card-content {
        font-size: 12px;
    }

    .add-to-cart {
        font-size: 12px;
        padding: 6px;
    }
}

/* SHOP PAGE END 320 */


/* SHOP PAGE START 480 */
@media only screen and (max-width: 480px) {
    .container-box {
        padding: 15px;
        margin-top: 140px;
        /* Adjust this value as needed */
    }

    .filter-control {
        width: 48%;
    }

    .product-media {
        height: 150px;
    }

    .card-content {
        font-size: 12px;
    }

    .add-to-cart {
        font-size: 12px;
        padding: 6px;
    }
}

/* SHOP PAGE END 480 */

/* SHOP PAGE START 576 */
@media only screen and (max-width: 576px) {
    .container-box {
        padding: 15px;
        margin-top: 140px;
        /* Adjust this value as needed */
    }

    .filter-control {
        width: 48%;
    }

    .product-media {
        height: 150px;
    }

    .card-content {
        font-size: 12px;
    }

    .add-to-cart {
        font-size: 12px;
        padding: 6px;
    }
}

/* SHOP PAGE END 576 */


/* SHOP PAGE START 768 */
@media only screen and (max-width: 768px) {
    .container-box {
        padding: 15px;
        margin-top: 140px;
        /* Adjust this value as needed */
    }

    .filter-control {
        width: 48%;
    }

    .product-media {
        height: 150px;
    }

    .card-content {
        font-size: 12px;
    }

    .add-to-cart {
        font-size: 12px;
        padding: 6px;
    }
}

/* SHOP PAGE END 768 */

/* SHOP PAGE START 920 */
@media only screen and (max-width: 920px) {
    .container-box {
        padding: 15px;
        margin-top: 140px;
        /* Adjust this value as needed */
    }

    .filter-control {
        width: 48%;
    }

    .product-media {
        height: 150px;
    }

    .card-content {
        font-size: 12px;
    }

    .add-to-cart {
        font-size: 12px;
        padding: 6px;
    }
}

/* SHOP PAGE END 920 */


/* SHOP PAGE START 1024 */
@media only screen and (max-width: 1024px) {
    .container-box {
        padding: 15px;
        margin-top: 140px;
        /* Adjust this value as needed */
    }

    .filter-control {
        width: 48%;
    }

    .product-media {
        height: 150px;
    }

    .card-content {
        font-size: 12px;
    }

    .add-to-cart {
        font-size: 12px;
        padding: 6px;
    }
}

/* SHOP PAGE END 1024 */

/* PRODDETAILS page styling START 320 */

@media (max-width: 320px) {
    .product-container {
        background-color: #d1984e94;
        min-height: 100vh;
        padding: 30px 30px;
        margin-top: 130px;
    }

    .product-image-card {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-details-card {
        padding: 15px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    .product-price {
        font-size: 18px;
        color: green;
        text-align: center;
        margin-top: 5px;
    }

    .product-subtitle {
        font-size: 14px;
        color: #666;
        text-align: center;
        margin-top: 5px;
    }

    .quantity-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .quantity-boxproddetails {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
    }

    .quantity-text {
        margin: 0 10px;
        font-size: 16px;
    }

    .add-to-cart-btn {
        width: 100%;
        margin-top: 15px;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 16px;
    }

    .customer-reviews-card {
        padding: 10px;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .review-box {
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    .review-author {
        font-weight: bold;
    }

    .review-rating {
        display: flex;
        align-items: center;
    }

    .rating-text {
        margin-left: 5px;
        font-size: 14px;
    }

    .review-text {
        margin-top: 5px;
        font-size: 14px;
        color: #444;
    }

    .no-reviews {
        font-size: 14px;
        color: #888;
        text-align: center;
        margin-top: 10px;
    }

    .review-heading {
        font-size: 16px;
        text-align: center;
        margin-top: 15px;
    }

    .review-subtext {
        font-size: 12px;
        color: #888;
        text-align: center;
        margin-bottom: 15px;
    }

    .write-review-btn {
        width: 100%;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 14px;
    }
}


/* PRODDETAILS page styling END 320 */



/* PRODDETAILS page styling START 480 */

@media (max-width: 480px) {
    .product-container {
        background-color: #d1984e94;
        min-height: 100vh;
        padding: 30px 30px;
        margin-top: 130px;
    }

    .product-image-card {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-details-card {
        padding: 15px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    .product-price {
        font-size: 18px;
        color: green;
        text-align: center;
        margin-top: 5px;
    }

    .product-subtitle {
        font-size: 14px;
        color: #666;
        text-align: center;
        margin-top: 5px;
    }

    .quantity-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .quantity-boxproddetails {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
    }

    .quantity-text {
        margin: 0 10px;
        font-size: 16px;
    }

    .add-to-cart-btn {
        width: 100%;
        margin-top: 15px;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 16px;
    }

    .customer-reviews-card {
        padding: 10px;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .review-box {
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    .review-author {
        font-weight: bold;
    }

    .review-rating {
        display: flex;
        align-items: center;
    }

    .rating-text {
        margin-left: 5px;
        font-size: 14px;
    }

    .review-text {
        margin-top: 5px;
        font-size: 14px;
        color: #444;
    }

    .no-reviews {
        font-size: 14px;
        color: #888;
        text-align: center;
        margin-top: 10px;
    }

    .review-heading {
        font-size: 16px;
        text-align: center;
        margin-top: 15px;
    }

    .review-subtext {
        font-size: 12px;
        color: #888;
        text-align: center;
        margin-bottom: 15px;
    }

    .write-review-btn {
        width: 100%;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 14px;
    }
}


/* PRODDETAILS page styling END 480 */

/* PRODDETAILS page styling START 576 */

@media (max-width: 576px) {
    .product-container {
        background-color: #d1984e94;
        min-height: 100vh;
        padding: 30px 30px;
        margin-top: 130px;
    }

    .product-image-card {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-details-card {
        padding: 15px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    .product-price {
        font-size: 18px;
        color: green;
        text-align: center;
        margin-top: 5px;
    }

    .product-subtitle {
        font-size: 14px;
        color: #666;
        text-align: center;
        margin-top: 5px;
    }

    .quantity-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .quantity-boxproddetails {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
    }

    .quantity-text {
        margin: 0 10px;
        font-size: 16px;
    }

    .add-to-cart-btn {
        width: 100%;
        margin-top: 15px;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 16px;
    }

    .customer-reviews-card {
        padding: 10px;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .review-box {
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    .review-author {
        font-weight: bold;
    }

    .review-rating {
        display: flex;
        align-items: center;
    }

    .rating-text {
        margin-left: 5px;
        font-size: 14px;
    }

    .review-text {
        margin-top: 5px;
        font-size: 14px;
        color: #444;
    }

    .no-reviews {
        font-size: 14px;
        color: #888;
        text-align: center;
        margin-top: 10px;
    }

    .review-heading {
        font-size: 16px;
        text-align: center;
        margin-top: 15px;
    }

    .review-subtext {
        font-size: 12px;
        color: #888;
        text-align: center;
        margin-bottom: 15px;
    }

    .write-review-btn {
        width: 100%;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 14px;
    }
}


/* PRODDETAILS page styling END 576 */


/* PRODDETAILS page styling START 768 */

@media (max-width: 768px) {
    .product-container {
        background-color: #d1984e94;
        min-height: 100%;
        padding: 50px 30px;
        margin-top: 130px;
    }

    .product-image-card {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-details-card {
        padding: 15px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    .product-price {
        font-size: 18px;
        color: green;
        text-align: center;
        margin-top: 5px;
    }

    .product-subtitle {
        font-size: 14px;
        color: #666;
        text-align: center;
        margin-top: 5px;
    }

    .quantity-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .quantity-boxproddetails {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
    }

    .quantity-text {
        margin: 0 10px;
        font-size: 16px;
    }

    .add-to-cart-btn {
        width: 100%;
        margin-top: 15px;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 16px;
    }

    .customer-reviews-card {
        padding: 10px;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .review-box {
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    .review-author {
        font-weight: bold;
    }

    .review-rating {
        display: flex;
        align-items: center;
    }

    .rating-text {
        margin-left: 5px;
        font-size: 14px;
    }

    .review-text {
        margin-top: 5px;
        font-size: 14px;
        color: #444;
    }

    .no-reviews {
        font-size: 14px;
        color: #888;
        text-align: center;
        margin-top: 10px;
    }

    .review-heading {
        font-size: 16px;
        text-align: center;
        margin-top: 15px;
    }

    .review-subtext {
        font-size: 12px;
        color: #888;
        text-align: center;
        margin-bottom: 15px;
    }

    .write-review-btn {
        width: 100%;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 14px;
    }
}


/* PRODDETAILS page styling END 768 */


/* PRODDETAILS page styling START 920 */

@media (max-width: 920px) {
    .product-container {
        background-color: #d1984e94;
        min-height: 100%;
        padding: 50px 30px;
        margin-top: 130px;
    }

    .product-image-card {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-details-card {
        padding: 15px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    .product-price {
        font-size: 18px;
        color: green;
        text-align: center;
        margin-top: 5px;
    }

    .product-subtitle {
        font-size: 14px;
        color: #666;
        text-align: center;
        margin-top: 5px;
    }

    .quantity-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .quantity-boxproddetails {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
    }

    .quantity-text {
        margin: 0 10px;
        font-size: 16px;
    }

    .add-to-cart-btn {
        width: 100%;
        margin-top: 15px;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 16px;
    }

    .customer-reviews-card {
        padding: 10px;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .review-box {
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    .review-author {
        font-weight: bold;
    }

    .review-rating {
        display: flex;
        align-items: center;
    }

    .rating-text {
        margin-left: 5px;
        font-size: 14px;
    }

    .review-text {
        margin-top: 5px;
        font-size: 14px;
        color: #444;
    }

    .no-reviews {
        font-size: 14px;
        color: #888;
        text-align: center;
        margin-top: 10px;
    }

    .review-heading {
        font-size: 16px;
        text-align: center;
        margin-top: 15px;
    }

    .review-subtext {
        font-size: 12px;
        color: #888;
        text-align: center;
        margin-bottom: 15px;
    }

    .write-review-btn {
        width: 100%;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 14px;
    }
}


/* PRODDETAILS page styling END 920 */


/* PRODDETAILS page styling START 1024 */

@media (max-width: 1024px) {
    .product-container {
        background-color: #d1984e94;
        min-height: 100%;
        padding: 50px 30px;
        margin-top: 130px;
    }

    .product-image-card {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-details-card {
        padding: 15px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .product-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    .product-price {
        font-size: 18px;
        color: green;
        text-align: center;
        margin-top: 5px;
    }

    .product-subtitle {
        font-size: 14px;
        color: #666;
        text-align: center;
        margin-top: 5px;
    }

    .quantity-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .quantity-boxproddetails {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
    }

    .quantity-text {
        margin: 0 10px;
        font-size: 16px;
    }

    .add-to-cart-btn {
        width: 100%;
        margin-top: 15px;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 16px;
    }

    .customer-reviews-card {
        padding: 10px;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .review-box {
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    .review-author {
        font-weight: bold;
    }

    .review-rating {
        display: flex;
        align-items: center;
    }

    .rating-text {
        margin-left: 5px;
        font-size: 14px;
    }

    .review-text {
        margin-top: 5px;
        font-size: 14px;
        color: #444;
    }

    .no-reviews {
        font-size: 14px;
        color: #888;
        text-align: center;
        margin-top: 10px;
    }

    .review-heading {
        font-size: 16px;
        text-align: center;
        margin-top: 15px;
    }

    .review-subtext {
        font-size: 12px;
        color: #888;
        text-align: center;
        margin-bottom: 15px;
    }

    .write-review-btn {
        width: 100%;
        background: linear-gradient(90deg, #00c6ff, #0072ff);
        color: white;
        padding: 10px 0;
        font-size: 14px;
    }
}


/* PRODDETAILS page styling END 1024 */


/* ADDTOCART STYLING START 320 */

@media (max-width: 320px) {
    .cart-container {
        background-color: #d1984e94;
        min-height: 100%;
        /* padding: 10px; */
    }

    .cart-title {
        font-size: 1.5rem;
    }

    .cart-empty h4,
    .cart-empty h5 {
        font-size: 0.9rem;
    }

    .cart-empty .btn-primary {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    .cart-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        background-color: white;
        border-radius: 8px;
        text-align: center;
        margin-bottom: 15px;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    }

    .cart-item:hover {
        transform: scale(1.05);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }

    .cart-product-image {
        max-height: 120px;
    }

    .cart-product-name {
        font-size: 1rem;
    }

    .cart-product-price,
    .cart-product-weight,
    .cart-product-total {
        font-size: 0.9rem;
    }

    .quantity-controls {
        flex-direction: column;
    }

    .quantity-btn {
        padding: 5px 8px;
        font-size: 0.9rem;
    }

    .remove-btn,
    .proceed-btn {
        font-size: 1rem;
        padding: 10px;
        width: 100%;
    }
}

/* ADDTOCART STYLING END 320 */


/* ADDTOCART STYLING START 480 */

@media (max-width: 480px) {
    .cart-container {
        background-color: #d1984e94;
        min-height: 100%;
        /* padding: 10px; */
    }

    .cart-title {
        font-size: 1.5rem;
    }

    .cart-empty h4,
    .cart-empty h5 {
        font-size: 0.9rem;
    }

    .cart-empty .btn-primary {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    .cart-item {
        padding: 10px;
    }

    .cart-product-image {
        max-height: 120px;
    }

    .cart-product-name {
        font-size: 1rem;
    }

    .cart-product-price,
    .cart-product-weight,
    .cart-product-total {
        font-size: 0.9rem;
    }

    .quantity-controls {
        flex-direction: column;
    }

    .quantity-btn {
        padding: 5px 8px;
        font-size: 0.9rem;
    }

    .remove-btn,
    .proceed-btn {
        font-size: 1rem;
        padding: 10px;
        width: 100%;
    }
}

/* ADDTOCART STYLING END 480 */


/* ADDTOCART STYLING START 576 */

@media (max-width: 576px) {
    .cart-container {
        background-color: #d1984e94;
        min-height: 100%;
        /* padding: 10px; */
    }

    .cart-title {
        font-size: 1.5rem;
    }

    .cart-empty h4,
    .cart-empty h5 {
        font-size: 0.9rem;
    }

    .cart-empty .btn-primary {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    .cart-item {
        padding: 10px;
    }

    .cart-product-image {
        max-height: 120px;
    }

    .cart-product-name {
        font-size: 1rem;
    }

    .cart-product-price,
    .cart-product-weight,
    .cart-product-total {
        font-size: 0.9rem;
    }

    .quantity-controls {
        flex-direction: column;
    }

    .quantity-btn {
        padding: 5px 8px;
        font-size: 0.9rem;
    }

    .remove-btn,
    .proceed-btn {
        font-size: 1rem;
        padding: 10px;
        width: 100%;
    }
}

/* ADDTOCART STYLING END 576 */


/* ADDTOCART STYLING START 768 */

@media (max-width: 768px) {
    .cart-container {
        background-color: #d1984e94;
        min-height: 100%;
        /* padding: 10px; */
    }

    .cart-title {
        font-size: 1.5rem;
    }

    .cart-empty h4,
    .cart-empty h5 {
        font-size: 0.9rem;
    }

    .cart-empty .btn-primary {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    .cart-item {
        padding: 10px;
    }

    .cart-product-image {
        max-height: 120px;
    }

    .cart-product-name {
        font-size: 1rem;
    }

    .cart-product-price,
    .cart-product-weight,
    .cart-product-total {
        font-size: 0.9rem;
    }

    .quantity-controls {
        flex-direction: column;
    }

    .quantity-btn {
        padding: 5px 8px;
        font-size: 0.9rem;
    }

    .remove-btn,
    .proceed-btn {
        font-size: 1rem;
        padding: 10px;
        width: 100%;
    }
}

/* ADDTOCART STYLING END 768 */


/* ADDTOCART STYLING START 920 */

@media (max-width: 920px) {
    .cart-container {
        background-color: #d1984e94;
        min-height: 100%;
        /* padding: 10px; */
    }

    .cart-title {
        font-size: 1.5rem;
    }

    .cart-empty h4,
    .cart-empty h5 {
        font-size: 0.9rem;
    }

    .cart-empty .btn-primary {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    .cart-item {
        padding: 10px;
    }

    .cart-product-image {
        max-height: 120px;
    }

    .cart-product-name {
        font-size: 1rem;
    }

    .cart-product-price,
    .cart-product-weight,
    .cart-product-total {
        font-size: 0.9rem;
    }

    .quantity-controls {
        flex-direction: column;
    }

    .quantity-btn {
        padding: 5px 8px;
        font-size: 0.9rem;
    }

    .remove-btn,
    .proceed-btn {
        font-size: 1rem;
        padding: 10px;
        width: 100%;
    }
}

/* ADDTOCART STYLING END 920 */


/* ADDTOCART STYLING START 1024 */

@media (max-width: 1024px) {
    .cart-container {
        background-color: #d1984e94;
        min-height: 100%;
        /* padding: 10px; */
    }

    .cart-title {
        font-size: 1.5rem;
    }

    .cart-empty h4,
    .cart-empty h5 {
        font-size: 0.9rem;
    }

    .cart-empty .btn-primary {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    .cart-item {
        padding: 10px;
    }

    .cart-product-image {
        max-height: 120px;
    }

    .cart-product-name {
        font-size: 1rem;
    }

    .cart-product-price,
    .cart-product-weight,
    .cart-product-total {
        font-size: 0.9rem;
    }

    .quantity-controls {
        flex-direction: column;
    }

    .quantity-btn {
        padding: 5px 8px;
        font-size: 0.9rem;
    }

    .remove-btn,
    .proceed-btn {
        font-size: 1rem;
        padding: 10px;
        width: 100%;
    }
}

/* ADDTOCART STYLING END 1024 */

/* Productpurchase styling START 320 */

@media (max-width: 320px) {
    .checkout-container {
        /* margin-top: 5rem;
        margin-bottom: 5rem; */
        padding: 0.5rem; /* Reduced padding */
        width: 100%; /* Ensure full width */
        overflow: hidden; /* Prevent horizontal scrolling */
    }

    .checkout-paper {
        padding: 0.8rem; /* Reduce padding */
    }

    .MuiStepper-root {
        flex-direction: column; /* Stack steps vertically */
        align-items: center; /* Center align */
        overflow-x: auto; /* Allow horizontal scrolling if needed */
        max-width: 100%;
        white-space: nowrap;
    }

    .MuiStepLabel-label {
        font-size: 12px; /* Reduce step label font size */
        word-wrap: break-word; /* Ensure text wraps properly */
        text-align: center;
    }

    .button-container {
        display: flex;
        flex-direction: column; /* Stack buttons */
        gap: 0.5rem;
        align-items: center;
    }

    .button-container button {
        width: 100%; /* Full width for buttons */
    }

    .total-amount-container {
        flex-direction: column; /* Stack elements */
        align-items: center;
        text-align: center;
    }

    .alert {
        font-size: 14px;
        padding: 0.5rem;
    }
}

/* Productpurchase styling END 320*/


/* Productpurchase styling START 480*/

@media (max-width: 480px) {
    .checkout-container {
        /* margin-top: 5rem;
        margin-bottom: 5rem; */
        padding: 0.5rem; /* Reduced padding */
        width: 100%; /* Ensure full width */
        overflow: hidden; /* Prevent horizontal scrolling */
    }

    .checkout-paper {
        padding: 0.8rem; /* Reduce padding */
    }

    .MuiStepper-root {
        flex-direction: column; /* Stack steps vertically */
        align-items: center; /* Center align */
        overflow-x: auto; /* Allow horizontal scrolling if needed */
        max-width: 100%;
        white-space: nowrap;
    }

    .MuiStepLabel-label {
        font-size: 12px; /* Reduce step label font size */
        word-wrap: break-word; /* Ensure text wraps properly */
        text-align: center;
    }

    .button-container {
        display: flex;
        flex-direction: column; /* Stack buttons */
        gap: 0.5rem;
        align-items: center;
    }

    .button-container button {
        width: 100%; /* Full width for buttons */
    }

    .total-amount-container {
        flex-direction: column; /* Stack elements */
        align-items: center;
        text-align: center;
    }

    .alert {
        font-size: 14px;
        padding: 0.5rem;
    }
}

/* Productpurchase styling START 480*/

/* Productpurchase styling START 576*/

@media (max-width: 576px) {
    .checkout-container {
        /* margin-top: 5rem;
        margin-bottom: 5rem; */
        padding: 0.5rem; /* Reduced padding */
        width: 100%; /* Ensure full width */
        overflow: hidden; /* Prevent horizontal scrolling */
    }

    .checkout-paper {
        padding: 0.8rem; /* Reduce padding */
    }

    .MuiStepper-root {
        flex-direction: column; /* Stack steps vertically */
        align-items: center; /* Center align */
        overflow-x: auto; /* Allow horizontal scrolling if needed */
        max-width: 100%;
        white-space: nowrap;
    }

    .MuiStepLabel-label {
        font-size: 12px; /* Reduce step label font size */
        word-wrap: break-word; /* Ensure text wraps properly */
        text-align: center;
    }

    .button-container {
        display: flex;
        flex-direction: column; /* Stack buttons */
        gap: 0.5rem;
        align-items: center;
    }

    .button-container button {
        width: 100%; /* Full width for buttons */
    }

    .total-amount-container {
        flex-direction: column; /* Stack elements */
        align-items: center;
        text-align: center;
    }

    .alert {
        font-size: 14px;
        padding: 0.5rem;
    }
}

/* Productpurchase styling START 576*/

/* Productpurchase styling START 768*/

@media (max-width: 768px) {
    .checkout-container {
        /* margin-top: 5rem;
        margin-bottom: 5rem; */
        padding: 0.5rem; /* Reduced padding */
        width: 100%; /* Ensure full width */
        overflow: hidden; /* Prevent horizontal scrolling */
    }

    .checkout-paper {
        padding: 0.8rem; /* Reduce padding */
    }

    .MuiStepper-root {
        flex-direction: column; /* Stack steps vertically */
        align-items: center; /* Center align */
        overflow-x: auto; /* Allow horizontal scrolling if needed */
        max-width: 100%;
        white-space: nowrap;
    }

    .MuiStepLabel-label {
        font-size: 12px; /* Reduce step label font size */
        word-wrap: break-word; /* Ensure text wraps properly */
        text-align: center;
    }

    .button-container {
        display: flex;
        flex-direction: column; /* Stack buttons */
        gap: 0.5rem;
        align-items: center;
    }

    .button-container button {
        width: 100%; /* Full width for buttons */
    }

    .total-amount-container {
        flex-direction: column; /* Stack elements */
        align-items: center;
        text-align: center;
    }

    .alert {
        font-size: 14px;
        padding: 0.5rem;
    }
}

/* Productpurchase styling START 768*/


/* USERPURCHASE HISTORY STYLING START 320 */

@media (max-width: 320px) {
    .order-details, .order-info, .card-style {
        width: 100%;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    .card-content {
        padding: 8px;
        font-size: 12px;
    }

    .review-button, .submit-review-button {
        width: 100%;
        font-size: 12px;
        padding: 4px 6px;
    }

    .order-message {
        font-size: 12px;
    }

    .MuiTypography-body2 {
        font-size: 12px !important;
    }

    .MuiDialog-paper {
        width: 95%;
        margin: auto;
    }

    .SmallProductImage {
        max-width: 50px;
        height: auto;
    }
}

/* USERPURCHASE HISTORY STYLING START 320 */


/* USERPURCHASE HISTORY STYLING START 480 */

@media (max-width: 480px) {
    .order-details, .order-info, .card-style {
        width: 100%;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    .card-content {
        padding: 8px;
        font-size: 12px;
    }

    .review-button, .submit-review-button {
        width: 100%;
        font-size: 12px;
        padding: 4px 6px;
    }

    .order-message {
        font-size: 12px;
    }

    .MuiTypography-body2 {
        font-size: 18px !important;
    }

    .MuiDialog-paper {
        width: 95%;
        margin: auto;
    }

    .SmallProductImage {
        max-width: 50px;
        height: auto;
    }
}

/* USERPURCHASE HISTORY STYLING START 480 */


/* USERPURCHASE HISTORY STYLING START 568 */

@media (max-width: 568px) {
    .order-details, .order-info, .card-style {
        width: 100%;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    .card-content {
        padding: 8px;
        font-size: 12px;
    }

    .review-button, .submit-review-button {
        width: 100%;
        font-size: 12px;
        padding: 4px 6px;
    }

    .order-message {
        font-size: 12px;
    }

    .MuiTypography-body2 {
        font-size: 18px !important;
    }

    .MuiDialog-paper {
        width: 95%;
        margin: auto;
    }

    .SmallProductImage {
        max-width: 50px;
        height: auto;
    }
}

/* USERPURCHASE HISTORY STYLING START 568 */