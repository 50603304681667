.h1,
.h2,
.h3,
a,
body,
div,
input,
label,
li,
p,
ul {
    font-family: Roboto, sans-serif
}

a,
body,
div,
input,
label,
li,
p,
ul {
    font-size: 18px !important;
    font-weight: 300 !important
}

.h1 {
    font-size: 48px !important;
    font-weight: 200 !important
}

.h2 {
    font-size: 30px !important;
    font-weight: 300
}

.h3 {
    font-size: 22px !important
}

.logo {
    font-weight: 500 !important
}

.text-warning {
    color: #ede861 !important
}

.text-muted {
    color: #bcbcbc !important
}

.text-success {
    color: #59ab6e !important
}

.text-light {
    color: #cfd6e1 !important
}

.bg-dark {
    background-color: #212934 !important
}

.bg-light {
    background-color: #e9eef5 !important
}

.bg-black {
    background-color: #1d242d !important
}

.bg-success,
.btn-success {
    background-color: #59ab6e !important
}

.btn-success {
    border-color: #56ae6c !important
}

.pagination .page-link.active,
.pagination .page-link:hover {
    background-color: #69bb7e;
    color: #fff
}

#templatemo_nav_top {
    min-height: 40px
}

#templatemo_nav_top * {
    font-size: .9em !important
}

#templatemo_main_nav a {
    color: #212934
}

#templatemo_main_nav a:hover {
    color: #69bb7e
}

#templatemo_main_nav .navbar .nav-icon {
    margin-right: 20px
}

#template-mo-zay-hero-carousel {
    background: #efefef !important
}

.templatemo-accordion a {
    color: #000
}

.templatemo-accordion a:hover {
    color: #333d4a
}

.shop-top-menu a:hover {
    color: #69bb7e !important
}

.product-wap {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1)
}

.product-wap .product-color-dot.color-dot-red {
    background: #f71515
}

.product-wap .product-color-dot.color-dot-blue {
    background: #6db4fe
}

.product-wap .product-color-dot.color-dot-black {
    background: #000
}

.product-wap .product-color-dot.color-dot-light {
    background: #e0e0e0
}

.product-wap .product-color-dot.color-dot-green {
    background: #0bff7e
}

.card.product-wap .card .product-overlay {
    background: rgba(0, 0, 0, .2);
    visibility: hidden;
    opacity: 0;
    transition: .3s
}

.card.product-wap:hover .card .product-overlay {
    visibility: visible;
    opacity: 1
}

.card.product-wap a {
    color: #000
}

#carousel-related-product .slick-slide:focus {
    outline: 0 !important
}

#carousel-related-product .slick-dots li button:before {
    font-size: 15px;
    margin-top: 20px
}

.brand-img {
    filter: grayscale(100%);
    opacity: .5;
    transition: .5s
}

.brand-img:hover {
    filter: grayscale(0);
    opacity: 1
}

#template-mo-zay-hero-carousel .carousel-indicators li {
    margin-top: -50px;
    background-color: #59ab6e
}

#template-mo-zay-hero-carousel .carousel-control-next i,
#template-mo-zay-hero-carousel .carousel-control-prev i {
    color: #59ab6e !important;
    font-size: 2.8em !important
}

.tempaltemo-carousel .h1 {
    font-size: .5em !important;
    color: #000 !important
}

.services-icon-wap {
    transition: .3s
}

.services-icon-wap:hover,
.services-icon-wap:hover i {
    color: #fff
}

.services-icon-wap:hover {
    background: #69bb7e
}

.leaflet-control,
.leaflet-control a {
    font-size: 10px !important
}

.form-control {
    border: 1px solid #e8e8e8
}

#tempaltemo_footer a {
    color: #dcdde1
}

#tempaltemo_footer a:hover {
    color: #68bb7d
}

#tempaltemo_footer ul.footer-link-list li {
    padding-top: 10px
}

#tempaltemo_footer ul.footer-icons li {
    width: 2.6em;
    height: 2.6em;
    line-height: 2.6em
}

#tempaltemo_footer ul.footer-icons li:hover {
    background-color: #cfd6e1;
    transition: .5s
}

#tempaltemo_footer ul.footer-icons li:hover i {
    color: #212934;
    transition: .5s
}

#tempaltemo_footer .border-light {
    border-color: #2d343f !important
}

.product-wap .h3,
.product-wap i,
.product-wap li,
.product-wap p {
    font-size: 12px !important
}

.product-wap .product-color-dot {
    width: 6px;
    height: 6px
}

@media (min-width:576px) {
    .tempaltemo-carousel .h1 {
        font-size: 1em !important
    }
}

@media (min-width:768px) {
    #templatemo_main_nav .navbar-nav {
        max-width: 450px
    }
}

@media (min-width:992px) {
    #templatemo_main_nav .navbar-nav {
        max-width: 550px
    }

    #template-mo-zay-hero-carousel .carousel-item {
        min-height: 30rem !important
    }

    .product-wap .h3,
    .product-wap i,
    .product-wap li,
    .product-wap p {
        font-size: 18px !important
    }

    .product-wap .product-color-dot {
        width: 12px;
        height: 12px
    }
}